import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const params = {
			order: 'desc',
			order_by: 'full_name',
			offset: 0,
			limit: 0,
		}
		const { data } = await useJwt.get(API_ROUTES.employee.get, params)

		return data
	},
	save(id, data) {
		return useJwt.put(`${API_ROUTES.employee.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.employee.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.update(`${API_ROUTES.employee.update}/${id}`, data)
	},
}
